<template>
    <section class="h-auto leaderboard-bg" id="leaderboard">
        <div class="flex flex-col justify-center items-center px-6 md:px-12 pt-14 md:pt-24 lg:pt-20 2xl:pt-52">
            <div class="border border-[#008F28] px-8 py-6">
                <div class="bg-gradient-to-r from-[#00FF0A] via-[#007D99] to-[#00FF0A] text-transparent bg-clip-text uppercase text-3xl md:text-5xl tracking-widest text-center" style="font-family: Russo One, sans-serif">
                    $10000 Stake Leaderboard
                </div>
            </div>
            <!-- <div v-motion-pop-visible class="pt-14 text-white text-xl font-black text-center">
                Under Construction. Coming Soon...
            </div> -->
        </div>
        <LeaderboardSection/>
    </section>
</template>

<script>
import LeaderboardSection from '../sections/LeaderboardSection.vue';
export default {
    components: {
        LeaderboardSection
    },
}
</script>

<style scoped>
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}
</style>
